import React from 'react';
import './App.css';
//import "slick-carousel/slick/slick.css";
//import "slick-carousel/slick/slick-theme.css";
import Layout from './components/layout/Layout';

function App() {
  return (
    <div className="App">
      <Layout />
      <div className="area" >

    </div >
    </div>
  );
}

export default App;
